import React from "react"

export default function Bio() {
  return (
    <>
      <b>Tyler Metevier</b>
      <br />
      Thoughts on Javascript and the Web
    </>
  )
}
